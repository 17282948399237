import { 
  ClientErrorCode, FetchReturn, FetchReturnError, GlobalMessage, SESSION_TIMEOUT_OPTION, SuccessCode, 
  SuccessNoResponseCode
} from '../store';
import store from '../store/store';
import { actions } from '../store';
import { sendMessage } from '../store/globalMessages/actions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PAGE_URL } from '../constants';
import { cloneDeep } from 'lodash-es';
import { AMPLIFY_ERROR_CODE } from './cognitoErrorUtils';
import { ActionType } from '../store/actionTypes';
import { cabCaptureException, cabCaptureMessage } from './logging';
import { fetchAuthSession as amplifyFetchAuthSession, signOut as amplifySignOut } from 'aws-amplify/auth';

interface FetchContent {
  headers?: { [key: string]: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  method?: string;
}

export const makeAuthHeader = (token?: string | null): string => {
  return `bearer ${token}`;
};

export const makeHeaders = async (shouldAuthenticate?: boolean): Promise<FetchContent['headers']> => {

  const actionCreators = bindActionCreators({
    logout: actions.auth.logout
  }, store.dispatch);

  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    'Pragma': 'no-cache',
    'FrontendVersion': import.meta.env.VITE_APP_FRONTEND_VERSION || '',
    'AppName': "app"
  };

  if (shouldAuthenticate) {
    try {
      const session = await amplifyFetchAuthSession();
      const token = session.tokens?.idToken?.toString() || null;
      if (token) {
        headers['Authorization'] = makeAuthHeader(token);
      }
    } catch (err) {
      // err can sometimes be a string or an Error object. Difficult to determine which until here.
      if (err instanceof Error && "code" in err && err['code'] !== AMPLIFY_ERROR_CODE.NETWORK_ERROR) {
        actionCreators.logout();
      }
    }
  }

  return headers;
};

interface Params {
  [key: string]: string | string[] | number | number[] | boolean | boolean[] | undefined;
}

export const makeParams = (params: Params): string => {
  const params_array: string[] = [];
  Object.keys(params).forEach((key: string): void => {
    let value = params[key];
    if (typeof value === 'boolean') {
      value = value ? 1 : 0;
    }
    if (value instanceof Array) {
      value.forEach((value2: string | number | boolean): void => {
        const sanitizedValue2 = typeof value2 === 'boolean' ? Number(value2) : value2;
        params_array.push(`${key}=${encodeURIComponent(sanitizedValue2)}`);
      });
    } else if (value !== undefined) {
      params_array.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  return "?" + params_array.join("&");
};

//Background is true for fetch requests that shouldn't show errors if the backend is unavailable at the moment
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchData = async <T = any, E extends FetchReturnError = string>(
  url: string, content: FetchContent, background?: boolean
): Promise<FetchReturn<T, E>> => {
  return fetch(url, content)
    .then(async (res): Promise<FetchReturn<T, E>> => {
      if (url.length > 2048) {
        cabCaptureMessage(`Fetched URL longer than 2048 characters: ${url}`);
      }

      const status = res.status as SuccessCode | ClientErrorCode | SuccessNoResponseCode;
      if (status === 410 && window.location.pathname !== PAGE_URL.UPDATE) {
        //API VERSION IS OUT OF DATE
        return res.json().then((data) => {
          if (data.error_code === 'invalid_cabinet_version') {
            const redirectPathname = window.location.pathname;
            window.history.replaceState({referrer: redirectPathname}, '', PAGE_URL.UPDATE);
            window.location.pathname = PAGE_URL.UPDATE;
            cabCaptureMessage('Incorrect Version: ' + url);
          }
          return { status: status, data };
        });
      } else if (status === 503 && window.location.pathname !== PAGE_URL.MAINTENANCE) {
        //API VERSION IS UNDER MAINTENANCE
        return res.json().then((data) => {
          if (data.error_code === 'maintenance_mode') {
            window.history.replaceState(data.details, '', PAGE_URL.MAINTENANCE);
            window.location.pathname = PAGE_URL.MAINTENANCE;
          }
          return { status: status, data };
        });
      } else if (
        status === 200 || status === 201 || status === 202 || status === 203 ||
        status === 205 || status === 206 || status === 207 || status === 208 || status === 226) {
        return res.json().then((data) => {
          return { status: status, data };
        });
      } else if (status === 204) {
        return { status: status };
      } else if (status === 401) {
        const data = await res.json();
        if (data.code === SESSION_TIMEOUT_OPTION.SESSION_TIMEOUT || 
          data.code === SESSION_TIMEOUT_OPTION.SESSION_TIMEOUT_NEW_DAY 
        ) {
          await amplifySignOut();
          store.dispatch({ type: ActionType.LOGOUT_SUCCESSFUL });
        }
        return {status: 401, data};
      } else if (status === 403) {
        const data = await res.json();
        // NOTE: This is the generic code returned by Django REST Framwork when a PermissionDenied
        // is hit
        if (data.code === "permission_denied" && window.location.pathname !== PAGE_URL.SIGNUP) {
          window.location.href = PAGE_URL.DASHBOARD;
        } else {
          store.dispatch({ 
            type: ActionType.SET_FETCH_ERROR,
            error: {status_code: res.status, message: data.detail}
          });
        }
        return {status: 403, data};
      } else if (status >= 500) {
        return res.json().then((data) => {
          cabCaptureException(Error(`${status} server error received for ${url}: ${JSON.stringify(data)}`));
          return { status: status, data };
        });
      } else {
        return res.json().then((data) => {
          return { status: status, data };
        });
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((res): Promise<FetchReturn<any, any>> => {
      if (res instanceof Error && !background) {
        const sanitizedContent = cloneDeep(content);
        if (sanitizedContent && sanitizedContent['headers'] && sanitizedContent['headers']['Authorization']) {
          sanitizedContent['headers']['Authorization'] = "SANITIZED";
        }
        
        console.log(JSON.stringify(res), url, JSON.stringify(sanitizedContent));
        cabCaptureException(res);
        // Returning valid structure here to avoid a redundant breaking exception in action creator
        if (['dev', 'stage'].includes(import.meta.env.VITE_DEPLOY_ENV || 'dev')) {
          // Only show this message on dev and stage.
          const message: GlobalMessage = {
            timeout: 5000,
            message: 'We\'re having trouble connecting - please try again in a few minutes.',
            autoDismiss: true,
            header: '',
            position: undefined,
            active: true,
            severity: "error",
          };

          sendMessage(message)(store.dispatch, store.getState, null);
        }
        return new Promise<FetchReturn>((resolve, reject): void => {
          resolve({ status: 999, data: { code: "network_error", detail: JSON.stringify(res) } });
        });
      } else if (!res) {
        // If a response is undefined, that's client problem and we don't want to
        // clutter Sentry or the console with it
        //console.log("Undefined response!");
        //Sentry.captureException(Error("Undefined response!"));
        return new Promise<FetchReturn<T>>((resolve, reject): void => {
          resolve({ status: 999, data: {code: "response_undefined", detail: "response undefined"} });
        });
      } else {
        // Report all other types of errors to sentry
        console.log('Server Error: ', res.statusText);
        cabCaptureException(Error(res.statusText));
        return new Promise<FetchReturn>((resolve, reject): void => {
          resolve({ status: res.status, data: {} });
        });
      }
    });
};

export interface AWSPresignedPost {
  url: string;
  fields: {
    acl: string;
    key: string;
    AWSAccessKeyId: string;
    signature: string;
  };
}

export const uploadFileS3 = async (
  presigned_post: AWSPresignedPost, file: Blob, image = false
): Promise<string> => {

  const s3_url = presigned_post.url;
  const fields = presigned_post.fields;

  const formData = new FormData();
  (Object.keys(fields) as (keyof AWSPresignedPost['fields'])[]).forEach((key): void => {
    formData.append(key, fields[key]);
  });

  //TODO: Specify content type for non-images
  if (image) {
    formData.append('Content-Type', file.type);
  }

  //TODO: Can also put filename here instead of sending to backend separately?
  formData.append('file', file);

  const res = await fetchData(s3_url, { method: 'POST', body: formData });
  if (res.status === 204) {
    return s3_url + fields.key;
  } else {
    return '';
  }
};

// Fetch a cookie by name
// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript?rq=1
export const getCookieValue = (name: string): string => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
);

export const parseFilesToB64Strings = async (files: File[]): Promise<string[]> => {
  const results = await files?.map(async (file) => {
    const result = await new Promise<string | ArrayBuffer | null>((res) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        res(reader.result);
      };
      reader.onerror = function (error) {
        throw Error("Parse file to string failed");
      };
    });
    return `${result}|${file.name}`;
  }) || [];

  return await Promise.all(results);
};
